import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CompetitionCard = () => {
  const [competitions, setCompetitions] = useState([]);
  const navigate = useNavigate();

  // Mock API call
  useEffect(() => {
    const apiUrl = 'https://www.admin.racepulse.pl/api/resultsGetEvents';
  
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'x-api-key': 'UYwkNH50yAFoHI1mR62E'
          }
        });
       
        const data = await response.json();
        setCompetitions(data);  // Assuming your API returns an array of competitions
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const handleClick = (EventID) => {
    // Navigate to the "results" page with the competition ID
   
    navigate(`/results/event-${EventID}`); 
  };

  return (
    <div className="columns is-multiline">
      {competitions.map((competition) => (
        <div key={competition.ID} className="column is-one-fifth">
          <div className="co-card">
            <div className="card-image">
              <img
                className="card-img"
                src={competition.logo} // Replace with the actual image URL from API
                alt={`Logo for ${competition.Name}`}
              />
            </div>
            <div className="co-card-content">
              <div className="media">
                <div className="media-content has-text-centered">
                  <p className="title is-4">{competition.name}</p>
                </div>
              </div>
              <div className="content">
                <button
                  onClick={() => handleClick(competition.ID)}
                  className="button is-large is-fullwidth is-warning is-outlined"
                >
                  Wyniki
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CompetitionCard;
