const fetchData = async (onDataFetched, EventID) => {
  const apiUrl = 'https://www.admin.racepulse.pl/api/resultsGetCompetitionDetails';

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'UYwkNH50yAFoHI1mR62E',
      },
      body: JSON.stringify({
        EventID: EventID,
       
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    const competitions = result.Competitions;

    const data = competitions.map((item) => {
      const sortedStages = item.Stages.sort((a, b) => a.StageOrder - b.StageOrder);
  
      return {
        IDCompetition: item.IDCompetition,
        NameCompetition: item.NameCompetition,
        Classifications: item.Classifications,
        Stages: sortedStages // Use the sorted stages here
      };
    });
    console.log('rally call',data)
    onDataFetched(data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export default fetchData;