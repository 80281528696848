import React, { useEffect, useState } from 'react';

const StageResultTable = ({
    selectedCompetition,
    selectedEventID,
    selectedCompetitionID,
    selectedGroupID,
    selectedStageID,
    selectedClassificationID
}) => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
 



    useEffect(() => {
     
      const apiUrl = 'https://www.admin.racepulse.pl/api/resultsGetStageResults';
    
        const getStageData = async () => {
          try {
            setLoading(true); // Set loading to true when starting to fetch data
            setError(null); // Reset the error state
            console.log('stageresults',selectedStageID,selectedClassificationID,selectedGroupID)
            const response = await fetch(apiUrl, {
              method: 'POST',
             headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'UYwkNH50yAFoHI1mR62E'
            
          },
              body: JSON.stringify({
                
                StageID: selectedStageID,
                ClassificationID: selectedClassificationID,
                GroupListID:selectedGroupID.join(',')
              }),
            });
    
            const data = await response.json();
           
         

            if ( response.status !== 200) { // Check if data.response exists before accessing code property
              setError('Brak Wyników.');
              setResults([]);
            } else {
              const cleanedData = data.map(item => ({
                ...item,
                // Replace newline characters in relevant fields
                Car: item.Car.replace(/\n/g, ''),
                // Add other fields if necessary
              }));
              setResults(cleanedData);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            setError('Error fetching data. Please try again.');
          } finally {
            setLoading(false);
          }
        };
    
        getStageData();
      }, [selectedStageID, selectedCompetitionID, selectedGroupID, selectedClassificationID]);

  const formatTime = (timeString) => {
    if (timeString === null) {
      return '--';
    }
      const [hours, minutes, secondsAndMillis] = timeString.split(':');
      const [seconds, milliseconds] = secondsAndMillis.split('.');
    
      let formattedTime = '';
    
      if (parseInt(hours, 10) !== 0) {
        formattedTime += `${parseInt(hours, 10)}:`;
      }
    
      formattedTime += `${parseInt(minutes, 10)}:${parseInt(seconds, 10)}.${milliseconds}`;
    
      return '+' + formattedTime.replace(/^0:/, ''); // Remove leading zero in hours
    };

    return (
      
          
            <div className="column is-half">
                <div className="has-text-centered ">
                    <p className="title is-4"> </p>
                </div>
                {loading ? (
                    <div className="loading-spinner">
                        {/* Spinning arrow will be displayed here */}
                    </div>
                ) : (
                    <>
                        {error ? (
                           
                              
                            <div class="notification has-text-centered mt-1">
                           
                            <strong> {error}</strong>
                          </div>
                        ) : (
                        <div className="result-table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                    <table>
                        <thead>
                            <tr>
                                <th className="center-text ">Poz</th>
                                <th className="center-text"> # </th>
                                <th className="center-text">Załoga</th>
                                <th className="center-text">Samochód &amp; Klasa</th>
                                <th className="center-text">Czas</th>
                                <th className="center-text">Różnica</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.map((result, index) => (
                                <tr key={index} >
                                    <td className="pos-column">
                                        <div className="position-box">{result.RowNumber}</div>
                                    </td>
                                    <td>
                                        <div className="car-number-box">#{result.PositionNumber}</div>
                                    </td>
                                    <td className="driver-info">
                                        {result.DriverName}
                                        {'\n'}
                                        {result.CODriverName}
                                    </td>
                                    <td className="car-info">
                                        {result.Car} {'\n'} {result.InGroup}
                                    </td>
                                    <td className="timeDiff-info">
                                    <strong>{result.ResultTime}</strong>
                                      
                                        {'\n'} 
                                      { result.Penalty == null ? '' :  <span class="tag is-danger is-light">+ {result.Penalty}</span>}
                                   
                                  
                                    
                                     
                                     </td>

                                    <td className="timeDiff-info">

                                      
                                    {formatTime(result.TimeDifferenceToFirst)}
                                    {'\n'} 
                                    {formatTime(result.DiffToPrev) }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                        )}
                </>
                )}
            </div> 

    );
};

export default StageResultTable;