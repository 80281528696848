import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarSide, faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const formatTime = (timeString) => {
  if (timeString === null) {
    return '--';
  }
  const [hours, minutes, secondsAndMillis] = timeString.split(':');
  const [seconds, milliseconds] = secondsAndMillis.split('.');
  
  let formattedTime = '';
  
  if (parseInt(hours, 10) !== 0) {
    formattedTime += `${parseInt(hours, 10)}:`;
  }
  
  formattedTime += `${parseInt(minutes, 10)}:${parseInt(seconds, 10)}.${milliseconds}`;
  
  return '+' + formattedTime.replace(/^0:/, ''); // Remove leading zero in hours
};

const LeaderboardTestItem = ({ data }) => {
  const isEven = parseInt(data.RowNumber) % 2 === 0;
  const isWinner = data.RowNumber === "1";
  const isOut = data.RowNumber === 'OUT';
  const textColor = isOut ? '#707070' : '#000000';

  // Assuming `AllResults` is an array of all result times for this driver-co-driver pair
  const allResults = data.AllResults || [];

  return (
    <>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isEven ? '#EFEFEF' : '#FFFFFF',
        padding: '10px',
        position: 'relative',
        fontFamily: 'Arial, sans-serif',
        borderBottom: '1px solid #DDDDDD',
        fontSize: '0.9em',
        color: textColor  
      }}>
      {/* Position and RowNumber box */}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        position: 'absolute',
        left: '5px',
        top: 0,
        bottom: 0,
      }}>
        <div style={{
          backgroundColor: textColor,
          borderRadius: '1ch',
          color: '#FFFFFF',
          padding: '3px 10px',
          fontWeight: 'bold'
        }}>
         {data.RowNumber}
        </div>
        <div style={{
          backgroundColor: '#707070',
          borderRadius: '1ch',
          color: '#FFFFFF',
          padding: '3px 10px',
          fontWeight: 'bold',
          marginTop: '5px'
        }}>
            {data.PositionNumber}
        </div>
      </div>
      
      {/* Offset for content so it doesn't overlap with the black box */}
      <div style={{ marginLeft: '47px', width: '100%' }}>
        <div style={{ fontWeight: 'bold' }}>{data.DriverName.trim()}</div>
        <div style={{ fontWeight: 'bold' }}>{data.CODriverName.trim()}</div>
        <div style={{ color: '#555', fontSize: 'smaller' }}>
        <FontAwesomeIcon icon={faLayerGroup} style={{ marginRight: '5px' ,marginLeft:'5px'}} />  {data.InGroup}  <FontAwesomeIcon icon={faCarSide} style={{ marginleft: '5px' }} /> {data.Car.trim()}
        </div>
      </div>
      
      <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
        <div>{data.ResultTime}</div>
        {data.Penalty && <div style={{ color: 'red', fontSize: 'smaller' }}>{`+${data.Penalty}`}</div>}
        {!isOut && !isWinner && <div style={{ fontSize: 'smaller' }}>{formatTime(data.DiffToPrev)}</div>}
        {!isOut && !isWinner && <div style={{ fontSize: 'smaller' }}>{formatTime(data.TimeDifferenceToFirst)}</div>}
      </div>


      </div>
      {/* Bottom row for displaying a list of results in a flex row */}
      <div style={{
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        backgroundColor: isEven ? '#EFEFEF' : '#FFFFFF',
        padding: '5px 10px',
        fontFamily: 'Arial, sans-serif',
        fontSize: '0.8em',
        borderBottom: '1px solid #DDDDDD',
        color: textColor
      }}>
        {allResults.map((result, index) => (
          <div key={index} style={{
            margin: '2px 5px',
            padding: '2px 10px',
            backgroundColor: '#F0F0F0',
            borderRadius: '10px'
          }}>
            {result}
          </div>
        ))}
      </div>
    </>
  );
};

export default LeaderboardTestItem;
