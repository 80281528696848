import React from 'react';
import { Helmet } from 'react-helmet';

function Header() {
  return (
    <div>
      <Helmet>
        <title>wyniki-online</title>
      </Helmet>
      {/* Rest of your component */}
    </div>
  );
}

export default Header;
