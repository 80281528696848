import React from 'react';
import CompetitionCard from './CompetitionCards';
import Navbar  from './Navbar';

const Home = () => {
  return (
    <div>
       <Navbar />
      <section className='section'>
      <CompetitionCard />
      </section>
    </div>
  );
}

export default Home;
