// Home.js, About.js, Contact.js, etc.
import React from 'react';
import Navbar from './Navbar';

const About = () => {
  return (
    <div>
      <Navbar />
      <h1>Home Page</h1>
      {/* Your Home page content */}
    </div>
  );
}

export default About;