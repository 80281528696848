import React from 'react';
import StageResultTable from './StageResultTable';
import ResultAfterStage from './ResultAfterStageTable';
import StageTestResultTable from './StageTestResult';

const ResultTable = ({
    selectedCompetition,
    selectedEventID,
    selectedCompetitionID,
    selectedGroupID,
    selectedStageID,
    StageStatusDesc,
    selectedStageIDStatus,
    selectedClassificationID,
    stageIsTested
}) => {
    return (
        <div>
            {selectedStageIDStatus ? (
                <div className="section">
                    <div className="notification has-text-centered mt-1">
                        <p>Odcinek odwołany {StageStatusDesc}</p>
                    </div>
                </div>
            ) : stageIsTested ? (
                <StageTestResultTable
                    selectedCompetition={selectedCompetition}
                    selectedEventID={selectedEventID}
                    selectedCompetitionID={selectedCompetitionID}
                    selectedGroupID={selectedGroupID}
                    selectedStageID={selectedStageID}
                    selectedClassificationID={selectedClassificationID}
                />
            ) : (
                <div className="columns">
                    <>
                        <StageResultTable
                            selectedCompetition={selectedCompetition}
                            selectedEventID={selectedEventID}
                            selectedCompetitionID={selectedCompetitionID}
                            selectedGroupID={selectedGroupID}
                            selectedStageID={selectedStageID}
                            selectedClassificationID={selectedClassificationID}
                        />
                        <ResultAfterStage
                            selectedCompetition={selectedCompetition}
                            selectedEventID={selectedEventID}
                            selectedCompetitionID={selectedCompetitionID}
                            selectedGroupID={selectedGroupID}
                            selectedStageID={selectedStageID}
                            selectedClassificationID={selectedClassificationID}
                        />
                    </>
                </div>
            )}
        </div>
    );
};

export default ResultTable;
