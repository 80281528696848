import React, { useState, useEffect } from 'react';
import LeaderboardTestItem from './LeaderboardTestItem'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';


const TestLeaderboard = ({
  selectedStageID,
  selectedClassificationID,
  selectedGroupID,
  selectedStageIDStatus,
  StageStatusDesc
}) => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const apiUrl = 'https://www.admin.racepulse.pl/api/resultsGetStageResults';
    
    const fetchLeaderboardData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'UYwkNH50yAFoHI1mR62E'
            
          },
          body: JSON.stringify({
            StageID: selectedStageID,
            ClassificationID: selectedClassificationID,
            GroupListID: selectedGroupID.join(',')
          }),
        });
  
        if (response.status !== 200) {
          throw new Error('Brak Wyników');
        }
  
        const data = await response.json();
        const groupedData = data.reduce((acc, item) => {
          // Use driver and co-driver names as a unique key for grouping
          const key = `${item.DriverName}-${item.CODriverName}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {});
  
        // Find the best ResultTime for each group
        Object.keys(groupedData).forEach(key => {
          groupedData[key].sort((a, b) => a.ResultTime.localeCompare(b.ResultTime));
          groupedData[key].bestTime = groupedData[key][0].ResultTime; // Assumes the times are in "HH:MM:SS.S" format
        });
  
        // Convert groupedData back to array format
        const leaderboardArray = Object.values(groupedData).map(group => ({
          ...group[0], // Take the first item as the base
          ResultTime: group.bestTime, // Override ResultTime with the best time
          AllResults: group.map(item => item.ResultTime) // Add an array of all results
        }));
  
        setLeaderboardData(leaderboardArray);
      } catch (error) {
        setError(error.toString());
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchLeaderboardData();

    // Handle scroll event
    const toggleScrollButton = () => {
      if (window.pageYOffset > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', toggleScrollButton);

    return () => window.removeEventListener('scroll', toggleScrollButton);
  }, [selectedStageID, selectedClassificationID, selectedGroupID]);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (isLoading) {
    return <div className="loading-spinner"></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
    {selectedStageIDStatus ? (
        <div className="section">
            <div className="notification has-text-centered mt-1">
                <p>Odcinek odwołany {StageStatusDesc}</p>
            </div>
        </div>
    ) : (
    <div className="section columns">
      <div className="notification has-text-centered mt-1 is-warning"> <strong> Czasy na odcinku</strong></div>
      <div className="columns result-table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <div style={{ backgroundColor: 'white' }}>
          {leaderboardData.map((data, index) => (
            <LeaderboardTestItem key={index} data={data} />
          ))}
        </div>
      </div>
      {showScrollButton && (
       <button
       onClick={scrollToTop}
   
       className="scroll-to-top-btn"
     >
       <FontAwesomeIcon icon={faArrowUp} size="2x" />
     </button>
      )}
    </div>
  
  )}
  </div>
);
};

export default TestLeaderboard;
