import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import MainNavbar from './Navbar';
import Results from './Results';
import ApiPage from './ApiPage';
import PagePenalties from './Penalties'; // Import PagePenalties component here
import Header from './Component/Helmet';



const App = () => {
  return (
    <Router>
      <div>
      <Header />
        <Routes>
          <Route path="/about" element={<MainNavbar showAllMenu={false} />} />
          <Route path="/penalties/*" element={<MainNavbar showAllMenu={true} />} />
          {/* <Route path="/results/*" element={<MainNavbar showAllMenu={true} />} />*/}
       {/*   <Route path="/" element={<MainNavbar showAllMenu={true} />} />*/}
          <Route path="/api" element={<MainNavbar showAllMenu={true} />} />
        </Routes>

        {/* Render different components based on the route */}
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/penalties/:eventID" element={<PagePenalties />} /> {/* Pass the eventID param */}
          <Route path="/results/:EventID" element={<Results />} />
          <Route path="/" element={<Home />} />
          <Route path="/api" element={<ApiPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
