import React, { useState, useEffect } from 'react';
import fetchData from './ApiPage';
import useMobileCheck from './useMobileCheck';
import BetaBanner from './Component/BetaBanner';

const CompetitionInfoCard = ({ onNavigateToResult, EventID }) => {
  const [competitionData, setCompetitionData] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState('');
  const [inGroupButtons, setInGroupButtons] = useState([]);
  const [selectedInGroupButton, setSelectedInGroupButton] = useState('');
  const [selectedCompetitionSteps, setSelectedCompetitionSteps] = useState([]);
  const [selectedGroupID, setSelectedGroupID] = useState(null);
  const [selectedStageID, setSelectedStageID] = useState(null);
  const [stageStatusDesc, setStageStatusDesc] = useState(null);
  const [stageIsTested, setStageIsTested] = useState(null);
  const [selectedStep, setSelectedStep] = useState(0);
  const [selectedCompetitionID, setSelectedCompetitionID] = useState(null);
  const [selectedClassificationID, setSelectedClassificationID] = useState(null);
  const [selectedStageIDStatus, setSelectedStageIDStatus] = useState(null);
  const [isClassificationClicked, setIsClassificationClicked] = useState(false);
  const [filteredClassificationData, setFilteredClassificationData] = useState([]); // New state for filtered classification data
  const [filteredInGroupData, setFilteredInGroupData] = useState([]);
  const [logoClassification, setLogoClassification] = useState([]);
  const labelStyle = { width: '100px' };
  const isMobile = useMobileCheck();

  // WebSocket connection
  useEffect(() => {
    console.log('ws1');
    const socket = new WebSocket('wss://www.admin.racepulse.pl:55127'); // Replace with your WebSocket server URL
    socket.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type === 'NEW_RESULT') {
        // Refresh or update the app
        window.location.reload();
      }
    };

    return () => {
      socket.close();
    };
  }, []);

  const handleStepChange = (e) => {
    const index = Number(e.target.value);
    handleStepClick(index);
  };

  const handleCompetitionChange = (e) => {
    const index = Number(e.target.value);
    handleCompetitionButtonClick(index); // Assuming this function sets the state based on the selected competition
  };

  const handleClassificationChange = (e) => {
    const classificationID = e.target.value;
    handleClassificationButtonClick(classificationID);
  };

  const handleGroupChange = (e) => {
    const groupID = e.target.value;
    handleInGroupButtonClick({ InGroupID: { GroupsListID: groupID } });
  };

  useEffect(() => {
    const onDataFetched = (data) => {
      setCompetitionData(data);

      const firstCompetition = data[0];
      const firstGroup = (firstCompetition.Classifications[0].CompetitionDetails.InGroup || [])[0];
      const firstStage = (firstCompetition.Stages || [])[0];
      const firstClassification = (firstCompetition.Classifications || [])[0];

      setSelectedCompetition(firstCompetition.NameCompetition);
      setSelectedCompetitionSteps(firstCompetition.Stages || []);
      setInGroupButtons(firstCompetition.Classifications[0].CompetitionDetails.InGroup || []);
      setSelectedGroupID(firstCompetition.Classifications[0].CompetitionDetails.InGroup[0].InGroupID.GroupsListID);
      setSelectedStep(0);
      setSelectedStageID(firstStage ? firstStage.StageID : null);
      setSelectedCompetitionID(firstCompetition.IDCompetition);
      setSelectedStageIDStatus(firstStage.StageStatus);
      setSelectedClassificationID(firstClassification ? firstClassification.ClassificationID : null);
      setSelectedInGroupButton(firstGroup ? firstGroup.InGroupID.GroupsListID : [0]);
      setStageIsTested(firstStage.IsTested);
      setLogoClassification(firstCompetition.Classifications[0].LogoClassification);
      onNavigateToResult(
        firstCompetition.NameCompetition,
        EventID,
        firstCompetition.IDCompetition,
        firstCompetition.Classifications[0].CompetitionDetails.InGroup[0].InGroupID.GroupsListID,
        firstStage.StageID,
        firstStage.StageStatus,
        firstStage.StageStatusDesc,
        firstClassification ? firstClassification.ClassificationID : null,
        firstStage.IsTested
      );

      setFilteredClassificationData(firstCompetition.Classifications || []);
      setFilteredInGroupData(firstCompetition.Classifications[0].CompetitionDetails.InGroup || []);
    };

    fetchData(onDataFetched, EventID);
  }, [EventID]);

  useEffect(() => {
    if (filteredClassificationData.length > 0) {
      const firstClassificationID = filteredClassificationData[0].ClassificationID;
      setSelectedClassificationID(firstClassificationID);
    }
  }, [filteredClassificationData]);

  const handleCompetitionButtonClick = (index) => {
    const selectedCompetition = competitionData[index];

    const competitionID = selectedCompetition.IDCompetition;
    const classificationID = selectedCompetition.Classifications.ClassificationID;
    const stages = selectedCompetition.Stages || [];
    const FirstGroup = selectedCompetition.Classifications[0].CompetitionDetails.InGroup[0].InGroupID.GroupsListID;
    const stageID = stages[0].StageID;

    setSelectedCompetition(selectedCompetition.NameCompetition);
    setSelectedCompetitionID(competitionID);
    setSelectedClassificationID(classificationID);
    setIsClassificationClicked(false);
    setSelectedCompetitionSteps(stages);
    setSelectedStageID(stageID);
    setFilteredClassificationData(selectedCompetition.Classifications || []);
    setSelectedInGroupButton(FirstGroup);
    setFilteredInGroupData(selectedCompetition.Classifications[0].CompetitionDetails.InGroup || []);
    setStageIsTested(stageID.IsTested);
    setSelectedStep(0);
    setSelectedGroupID(getFirstGroupID(FirstGroup));
    setLogoClassification(selectedCompetition.Classifications[0].LogoClassification);

    onNavigateToResult(
      competitionID,
      EventID,
      selectedCompetition.IDCompetition,
      getFirstGroupID(FirstGroup),
      stageID,
      stageStatusDesc,
      selectedStageIDStatus,
      selectedCompetition.Classifications[0].ClassificationID,
      stages[0].IsTested
    );
  };

  const handleInGroupButtonClick = (button) => {
    setSelectedInGroupButton(button.InGroupID.GroupsListID);
    const groupID = getGroupID(button.InGroupID.GroupsListID);
    setSelectedGroupID(getFirstGroupID(button.InGroupID.GroupsListID));

    onNavigateToResult(
      selectedCompetition,
      EventID,
      selectedCompetitionID,
      getFirstGroupID(button.InGroupID.GroupsListID),
      selectedStageID,
      stageStatusDesc,
      selectedStageIDStatus,
      selectedClassificationID,
      stageIsTested
    );
  };

  const handleClassificationButtonClick = (classificationID) => {
    const selectedClassification = filteredClassificationData.find((cls) => cls.ClassificationID === classificationID);
    setIsClassificationClicked(true);
    setSelectedClassificationID(classificationID);
    setSelectedInGroupButton(selectedClassification.CompetitionDetails.InGroup[0].InGroupID.GroupsListID);
    setFilteredInGroupData(selectedClassification.CompetitionDetails.InGroup || []);

    onNavigateToResult(
      selectedCompetition,
      EventID,
      selectedCompetitionID,
      selectedClassification.CompetitionDetails.InGroup[0].InGroupID.GroupsListID,
      selectedStageID,
      stageStatusDesc,
      selectedStageIDStatus,
      classificationID,
      stageIsTested
    );
  };

  const getGroupID = (button) => {
    const filteredGroups = inGroupButtons
      .filter((btn) => btn.Group === button)
      .map((btn) => btn.InGroupID?.GroupsListID);
    return filteredGroups;
  };

  const getFirstGroupID = (GroupList) => {
    return [GroupList];
  };

  const handleStepClick = (index) => {
    setSelectedStep(index);
    const stageID = selectedCompetitionSteps[index].StageID;
    const stageStatus = selectedCompetitionSteps[index].StageStatus;
    const stageStatusDesc = selectedCompetitionSteps[index].StageStatusDesc;
    const isTested = selectedCompetitionSteps[index].IsTested;
    setSelectedStageID(stageID);
    setSelectedStageIDStatus(stageID.StageStatus);
    setStageIsTested(isTested);

    onNavigateToResult(
      selectedCompetition,
      EventID,
      selectedCompetitionID,
      selectedGroupID,
      stageID,
      stageStatus,
      stageStatusDesc,
      selectedClassificationID,
      isTested
    );
  };

  const getStepClass = (index, selectedStep, step) => {
    const baseClass = 'steps-marker ';
    const isActiveClass = index === selectedStep ? 'is-active is-pulsating' : '';
    const isActive = index === selectedStep ? true : false;
    const pulsatingClass = isActive === false && step.StageStatus === 0 ? '' : '';
    const isBlocked = step.StageStatus === 1 && isActive === false ? 'is-hollow' : '';

    return `${baseClass} ${isActiveClass} ${pulsatingClass} ${isBlocked}`;
  };

  return (
    <div className="columns is-multiline competition-card is-centered">
      {!isMobile ? (
        <div className="column is-one-third custom-card">
          <div className="card-image">
            <img
              src={logoClassification}
              alt="Placeholder image"
              style={{ maxWidth: '35%', height: 'auto' }}
            />
          </div>

          <div className="media">
            <div className="media-content has-text-centered">
              <p className="title is-6">{selectedCompetition || 'Wybierz Zawody'}</p>
            </div>
          </div>
          <div className="media">
            <div className="media-content has-text-centered">
              <p className="title is-6">
                {selectedStep >= 0 && selectedStep < selectedCompetitionSteps.length
                  ? selectedCompetitionSteps[selectedStep].StageDetails
                  : 'Wybierz odcinek'}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {/* Second Column */}
      <div className={`column ${isMobile ? '' : 'custom-divider'} `}>
        {/* Nested Columns for the first new row */}
        <div className="columns is-multiline">
          {isMobile ? (
            <div className="column is-full">
              <div className="field is-grouped">
                <div className="control is-flex is-align-items-center">
                  <label className="label mr-3" style={labelStyle}>
                    Klasyfikacje
                  </label>
                </div>
                <div className="control is-expanded">
                  <div className="select is-fullwidth select-width-consistent">
                    <select onChange={handleCompetitionChange}>
                      {competitionData.map((competition, index) => (
                        <option key={index} value={index}>
                          {competition.NameCompetition}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="column is-full">
              <div className="columns is-vcentered ">
                <div className="column is-narrow">
                  <p className="subtitle is-5">Zawody: </p>
                </div>
                <div className="column">
                  {/* Existing buttons for desktop */}
                  <div className="buttons custom-card-buttons">
                    {competitionData.map((competition, index) => (
                      <button
                        key={index}
                        className={`button ${competition.NameCompetition === selectedCompetition ? 'is-warning' : ''}`}
                        onClick={() => handleCompetitionButtonClick(index)}
                      >
                        {competition.NameCompetition}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {isMobile ? (
            <div className="column is-full">
              <div className="field is-grouped">
                <div className="control is-flex is-align-items-center">
                  <label className="label mr-3" style={labelStyle}>
                    Zawody
                  </label>
                </div>
                <div className="control is-expanded">
                  <div className="select is-fullwidth select-width-consistent">
                    <select onChange={handleClassificationChange} value={selectedClassificationID}>
                      {filteredClassificationData.map((classification, index) => (
                        <option key={index} value={classification.ClassificationID}>
                          {classification.ClassificationName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="column is-full">
              <div className="columns is-vcentered ">
                <div className="column is-narrow">
                  <p className="subtitle is-5">Klasyfikacje: </p>
                </div>
                <div className="column">
                  {/* Existing buttons for desktop */}
                  <div className="buttons custom-card-buttons">
                    {filteredClassificationData.map((classification, index) => (
                      <button
                        key={index}
                        className={`button ${classification.ClassificationID === selectedClassificationID ? 'is-warning' : ''}`}
                        onClick={() => handleClassificationButtonClick(classification.ClassificationID)}
                      >
                        {classification.ClassificationName}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {isMobile ? (
            <div className="column is-full">
              <div className="field is-grouped">
                <div className="control is-flex is-align-items-center">
                  <label className="label mr-3" style={labelStyle}>
                    Klasy
                  </label>
                </div>
                <div className="control is-expanded">
                  <div className="select is-fullwidth select-width-consistent">
                    <select onChange={handleGroupChange} value={selectedInGroupButton}>
                      {filteredInGroupData.map((group, index) => (
                        <option key={index} value={group.InGroupID.GroupsListID}>
                          {group.InGroupName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="column is-full">
              <div className="columns is-vcentered ">
                <div className="column is-narrow">
                  <p className="subtitle is-5">Klasy: </p>
                </div>
                <div className="column">
                  {/* Existing buttons for desktop */}
                  <div className="buttons custom-card-buttons">
                    {filteredInGroupData.map((group, index) => (
                      <button
                        key={index}
                        className={`button ${group.InGroupID.GroupsListID === selectedInGroupButton ? 'is-warning' : ''}`}
                        onClick={() => handleInGroupButtonClick(group)}
                      >
                        {group.InGroupName}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isMobile ? (
        <div className="column is-full">
          <div className="field is-grouped">
            <div className="control is-flex is-align-items-center">
              <label className="label mr-3" style={labelStyle}>
                Odcinki
              </label>
            </div>
            <div className="control is-expanded">
              <div className="select is-fullwidth select-width-consistent">
                <select onChange={handleStepChange} value={selectedStep}>
                  {selectedCompetitionSteps.map((step, index) => (
                    <option key={index} value={index}>
                      {step.StageType}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Existing steps navigation as segments

        <div className="column is-full">
          <ul className="steps is-thin has-content-centered">
            {selectedCompetitionSteps.map((step, index) => (
              <li
                key={index}
                className={`steps-segment ${index === selectedStep ? 'is-active  ' : ''}`}
                onClick={() => handleStepClick(index)}
              >
                <span className={getStepClass(index, selectedStep, step)}></span>
                <div className="steps-content">
                  <p className={` ${index === selectedStep ? ' is-size-5 is-selected-step  ' : 'is-size-6 '}`}>{step.StageType}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div>
        <BetaBanner />
      </div>
    </div>
  );
};

export default CompetitionInfoCard;
