import React from 'react';

const BetaBanner = () => {
  return (
    <div >
      
      Wersja testowa. Mogą pojawić sie błedy w wynikach .
    </div>
  );
};

export default BetaBanner;
