import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './assets/logo/pzm.jpg';
import 'bulma/css/bulma.min.css'; // Ensure Bulma is imported

const Navbar = ({ EventID }) => {
  const location = useLocation();
  const [webLink, setWebLink] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const setWebLinkWithProtocol = (link) => {
    if (link.startsWith('http://') || link.startsWith('https://')) {
      setWebLink(link);
    } else {
      setWebLink('http://' + link);
    }
  };

  useEffect(() => {
    const apiUrl = 'https://www.admin.racepulse.pl/api/resultsWebAddresForEvent';
    const fetchWebLink = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'UYwkNH50yAFoHI1mR62E',
          },
          body: JSON.stringify({
            EventID: EventID,
          }),
        });

        const data = await response.json();

        if (response.status === 200 && data.length > 0) {
          setWebLinkWithProtocol(data[0].WebAddress);
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setShowButton(false);
      }
    };

    fetchWebLink();
  }, [EventID]);

  return (
    <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={logo} alt="PZM" style={{ maxHeight: '2.75rem' }} />
          </Link>
          <a role="button" className={`navbar-burger burger ${isActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" onClick={() => setIsActive(!isActive)}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
          <div className="navbar-end">
            <Link to="/" className={`navbar-item ${location.pathname === '/' ? 'is-active' : ''}`}>
              Start
            </Link>
            {showButton && webLink && (
              <a href={webLink} className="navbar-item" target="_blank" rel="noopener noreferrer">
                Więcej Informacji
              </a>
            )}
             {location.pathname.startsWith('/results') ? (
              <>
                
                
                <Link to={`/penalties/${getEventIDFromPath(location.pathname)}`} className={`navbar-item ${location.pathname === '/penalties' ? 'active' : ''}`}>
                  Kary
                </Link>
              </>
            ) : location.pathname === '/' ? (
              <>
                <Link to="/about" className={`navbar-item ${location.pathname === '/about' ? 'active' : ''}`}>
                  Archiwum Wyników
                </Link>
              </>
            ) : (
              <Link to={`/results/${getEventIDFromResult(location.pathname)}`} className={`navbar-item ${location.pathname === '/results' ? 'active' : ''}`}>
                Wyniki
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
// Function to extract EventID from pathname
const getEventIDFromPath = (pathname) => {
  const parts = pathname.split('/');
  const eventIDIndex = parts.findIndex(part => part === 'results') + 1;
  return parts[eventIDIndex];
}

const getEventIDFromResult = (pathname) => {
  const parts = pathname.split('/');
  const eventIDIndex = parts.findIndex(part => part === 'penalties') + 1;
  return parts[eventIDIndex];
}

export default Navbar;
