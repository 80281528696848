import React, { useEffect, useState } from 'react';

const StageTestResultTable = ({
    selectedCompetition,
    selectedEventID,
    selectedCompetitionID,
    selectedGroupID,
    selectedStageID,
    selectedClassificationID
}) => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
 



    useEffect(() => {
     
      const apiUrl = 'https://www.admin.racepulse.pl/api/resultsGetStageResults';
        const getStageData = async () => {
          try {
            setLoading(true); // Set loading to true when starting to fetch data
            setError(null); // Reset the error state
            console.log('stageresults',selectedStageID,selectedClassificationID,selectedGroupID)
            const response = await fetch(apiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'UYwkNH50yAFoHI1mR62E',
              },
              body: JSON.stringify({
                
                StageID: selectedStageID,
                ClassificationID: selectedClassificationID,
                GroupListID:selectedGroupID.join(',')
              }),
            });
    
            const data = await response.json();
           
         

            if ( response.status !== 200) { // Check if data.response exists before accessing code property
              setError('Brak Wyników.');
              setResults([]);
            } else {
              const cleanedData = data.map(item => ({
                ...item,
                // Replace newline characters in relevant fields
                Car: item.Car.replace(/\n/g, ''),
                // Add other fields if necessary
              }));
              console.log('apical',cleanedData)
              setResults(cleanedData);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            setError('Error fetching data. Please try again.');
          } finally {
            setLoading(false);
          }
        };
    
        getStageData();
      }, [selectedStageID, selectedCompetitionID, selectedGroupID, selectedClassificationID]);

  const formatTime = (timeString) => {
    if (timeString === null) {
      return '--';
    }
      const [hours, minutes, secondsAndMillis] = timeString.split(':');
      const [seconds, milliseconds] = secondsAndMillis.split('.');
    
      let formattedTime = '';
    
      if (parseInt(hours, 10) !== 0) {
        formattedTime += `${parseInt(hours, 10)}:`;
      }
    
      formattedTime += `${parseInt(minutes, 10)}:${parseInt(seconds, 10)}.${milliseconds}`;
    
      return '+' + formattedTime.replace(/^0:/, ''); // Remove leading zero in hours
    };
    const findBestTime = (driverResults) => {
      const sortedTimes = driverResults.map(result => result.ResultTime).sort();
      return sortedTimes[0]; // Returns the best time assuming the times are sorted in ascending order
    };
  
    // Process results to group them by driver/co-driver pairs and find the best time
    const processedResults = results.reduce((acc, result) => {
      const driverIdentifier = `${result.DriverName}-${result.CODriverName}`;
      
      if (!acc[driverIdentifier]) {
        acc[driverIdentifier] = {
          PositionNumber:result.PositionNumber,
          driver: result.DriverName,
          coDriver: result.CODriverName,
          car:result.Car,
          group:result.InGroup,
          times: [],
          bestTime: null,
        };
      }
      
      acc[driverIdentifier].times.push(result);
      
      return acc;
    }, {});
  
    // Find the best time for each driver/co-driver pair
    Object.values(processedResults).forEach(driverResults => {
      driverResults.bestTime = findBestTime(driverResults.times);
    });
  
    // Find the maximum number of stage times to determine the number of columns
    const maxStages = Math.max(...Object.values(processedResults).map(driverResults => driverResults.times.length));
  
    return (
      <div className="column ">
        {/* ...loading and error handling */}
        <div className="result-table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <table>
            <thead>
              <tr>
              <th className="center-text ">Poz</th>
               <th className="center-text"> # </th>
               <th className="center-text">Załoga</th>
               <th className="center-text">Samochód &amp; Klasa</th>
                <th className="center-text">Najlepszy czas</th>
                {Array.from({ length: maxStages }).map((_, index) => (
                  <th key={index} className="center-text">Czas {index + 1}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.values(processedResults).map((driverResults, index) => (
                <tr key={index}>
                  <td className="pos-column">
                                        <div className="position-box">{index+1}</div>
                                    </td>
                                    <td>
                                        <div className="car-number-box">#{driverResults.PositionNumber}</div>
                                    </td>
                                    <td className="driver-info">
                                        {driverResults.driver}
                                        {'\n'}
                                        {driverResults.coDriver}
                                    </td>
                                    <td className="car-info">
                                        {driverResults.car} {'\n'} {driverResults.group}
                                    </td>
                  
                 
                  <td className="best-time-info"><b>{driverResults.bestTime}</b></td>
                  {/* Fill in each stage time for the driver/co-driver pair */}
                  {Array.from({ length: maxStages }).map((_, timeIndex) => {
                    const timeResult = driverResults.times[timeIndex];
                    const time = timeResult ? timeResult.ResultTime : '--';
                    return (
                      <td key={timeIndex} className={`time-info ${time === driverResults.bestTime ? 'best-time' : ''}`}>
                        {time}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  
  export default StageTestResultTable;