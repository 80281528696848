// Leaderboard.js
import React, { useState, useEffect } from 'react';
import LeaderboardItemAfterStage from './LeaderboardItemAfterStage'; // A component to render each leaderboard item

const MobileResultAfterStage = ({
  selectedStageID,
  selectedClassificationID,
  selectedGroupID
}) => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const apiUrl = 'https://www.admin.racepulse.pl/api/resultsGetAfterStageResults';
    const fetchLeaderboardData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'UYwkNH50yAFoHI1mR62E'
            
          },
          body: JSON.stringify({
            StageID: selectedStageID,
            ClassificationID: selectedClassificationID,
            GroupListID: selectedGroupID.join(',')
          }),
        });

        if (response.status !== 200) {
          throw new Error('Brak Wyników'); // "No results" in Polish
        }

        const data = await response.json();
        setLeaderboardData(data.map(item => ({
          ...item,
          Car: item.Car.replace(/\n/g, ' '),
          // Adjust other fields if necessary
        })));
      } catch (error) {
        setError(error.toString());
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeaderboardData();
  }, [selectedStageID, selectedClassificationID, selectedGroupID]);

  if (isLoading) {
    return <div className="loading-spinner"></div>
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
  
    
    <div className="section columns">
      <div class="notification has-text-centered mt-1 is-warning"> <strong> Czasy po odcinku </strong></div>
      <div className=" columns result-table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <div style={{ backgroundColor: 'white' }}>
        {leaderboardData.map((data, index) => (
          <LeaderboardItemAfterStage key={index} data={data} />
        ))}
      </div>
    </div> </div>
  );
};

export default MobileResultAfterStage;
